<template>
  <div id="detail-z">
    <SEARCH :searchChange="onCallbackSearch" :list="searchArr"></SEARCH>
    <div class="msg">
      <el-checkbox
        @change="clearDataArr"
        style="margin-right: 10px"
        size="small"
        v-model="checked"
        label="仅看已选"
        border
      ></el-checkbox>
      <TABS :tabChange="onCallbackTab" :list="tabsArr"></TABS>
      <div class="info">
        <span style="color: #1abeae">已选择：</span>单选{{ only }}题/{{
          score.onlyNum
        }}分，多选{{ more }}题/{{ score.moreNum }}分，判断{{ tOrf }}题/{{
          score.tOrfNum
        }}分，总分：{{ score.total }}分。
      </div>
    </div>
    <el-tabs v-model="labelVal" type="border-card">
      <el-tab-pane label="单选题">
        <TABLE
          ref="table"
          :selectChange="onCallbackSelect"
          :list="tableArr"
          :data="dataArr"
        ></TABLE>
      </el-tab-pane>
      <el-tab-pane label="多选题">
        <TABLE
          ref="table"
          :selectChange="onCallbackSelect"
          :list="tableArr"
          :data="dataArr"
        ></TABLE>
      </el-tab-pane>
      <el-tab-pane label="判断题">
        <TABLE
          ref="table"
          :selectChange="onCallbackSelect"
          :list="tableArr"
          :data="dataArr"
        ></TABLE>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import SEARCH from '@/components/search' // 顶部搜索
import TABS from '@/components/tabs' // 横向 button
import TABLE from '@/components/table' // 列表

import { getExamTestQues, getETQSelect, addETPaper } from '@/api/safety'
export default {
  name: 'TestPaperAdd',
  components: {
    SEARCH,
    TABS,
    TABLE,
  },
  data() {
    return {
      searchArr: [
        {
          name: '类别',
          type: 'picker',
          options: [],
          key: 'type',
          label: 'name',
          value: 'id',
        },
        {
          name: '题目',
          type: 'input',
          key: 'title',
        },
      ], // 搜索列表
      tabsArr: [
        {
          type: 'warning',
          name: '清除修改',
          func: 'clearOptions',
          isLoading: false,
        },
        {
          type: 'primary',
          name: '保存修改',
          func: 'saveExam',
          isLoading: false,
        },
      ], // 标签列表
      tableArr: [
        {
          prop: 'serial_number',
          label: '编号',
          width: '200',
        },
        {
          prop: 'type2_id',
          label: '类别',
          width: '200',
        },
        {
          prop: 'type',
          label: '题型',
          width: '200',
        },
        {
          prop: 'content',
          label: '题目',
          width: '706',
        },
        {
          prop: 'answer',
          label: '答案',
          width: '120',
        },
        {
          prop: 'score',
          label: '分值',
          width: '120',
        },
      ], // 表头列表
      dataArr: [], // 数据列表
      count: 0, // 总页数
      formData: {
        type2_id: '',
        type: '',
        content: '',
      },
      labelVal: '',
      checked: false,
      tOrf: 0, // 判断题 几道题
      only: 0, // 单选
      more: 0, // 多选
      score: {
        tOrfNum: 0, // 多少分
        onlyNum: 0,
        moreNum: 0,
        total: 0, // 总分
      },
      allIds: [],
      backup: [], // 备份数据
      tabsIndex: 0, // 选中的 tabs
    }
  },
  props: {
    id: {
      type: String,
      default() {
        return ''
      },
    },
    ids: {
      type: Array,
      default() {
        return []
      },
    },
    arr: {
      type: Array,
      default() {
        return []
      },
    },
  },
  watch: {
    labelVal(newV) {
      this.formData.type = String(Number(newV) + 1)
      this._loadData()
    },
  },
  created() {
    this.ids = this.ids.map(s => Number(s))
    this.allIds = JSON.parse(JSON.stringify(this.ids))
    console.log(this.arr)
    this.arr.forEach(s => {
      if (s.type === 1) {
        this.only = s.num
        this.score.onlyNum = Number(s.score)
      } else if (s.type === 2) {
        this.more = s.num
        this.score.moreNum = Number(s.score)
      } else if (s.type === 3) {
        this.tOrf = s.num
        this.score.tOrfNum = Number(s.score)
      }
    })
    const { onlyNum, moreNum, tOrfNum } = this.score
    this.score.total = onlyNum + moreNum + tOrfNum
    this._loadPicker()
  },
  mounted() {},
  methods: {
    // 获取下拉框数据
    _loadPicker: function () {
      // 类别
      getETQSelect().then((res) => {
        const { result } = res
        result.unshift({ id: '', name: '全部' })
        this.searchArr[0].options = result
      })
    },

    // 获取列表数据
    _loadData: function () {
      this.dataArr = []
      getExamTestQues(this.formData).then((res) => {
        const { result } = res
        result.data.forEach((item) => {
          const index = this.allIds.indexOf(item.id)
          if (index > -1) item.select = true
          else item.select = false
        })
        this.backup = result.data
        if (this.checked) this.clearDataArr()
        else this.dataArr = JSON.parse(JSON.stringify(this.backup))
      })
    },

    // 保存试题
    saveExam: function() {
      if (!this.allIds.length) {
        this.loading()
        this.$toast('请添加试题')
        return false
      }
      const data = {
        id: this.id,
        qids: this.allIds.join(','),
      }
      addETPaper(data).then(res => {
        this.loading()
        this.$toast(res.message)
      })
    },

    // 只看选中的
    clearDataArr: function () {
      if (this.checked) {
        this.dataArr = this.backup.filter((item) => {
          item.select = true
          return this.allIds.indexOf(item.id) > -1
        })
      } else {
        this._loadData()
      }
    },

    // 清洗选中的
    clearIds: function (str, key, keyNum) {
      if (str) {
        const arr = str.split(',')
        let number = 0
        this[key] = arr.length
        arr.forEach((item) => {
          this.dataArr.forEach((sub) => {
            if (Number(item) === sub.id) {
              number += Number(sub.score)
              this.allIds.push(Number(item))
            }
          })
        })
        this.score[keyNum] = number

        this.allIds = [...new Set(this.allIds)]
      } else {
        this[key] = 0
        this.score[keyNum] = 0
        this.dataArr.forEach((sub) => {
          this.allIds.forEach((s, index) => {
            if (s === sub.id) this.allIds.splice(index, 1)
          })
        })
      }
      const { tOrfNum, onlyNum, moreNum } = this.score
      this.score.total = tOrfNum + onlyNum + moreNum
    },

    // 清除全部选中的
    clearOptions: function () {
      this.$toast('已清除')
      this.allIds = []
      this.loading()
      this.tOrf = 0
      this.only = 0
      this.more = 0
      this.score = {
        tOrfNum: 0, // 多少分
        onlyNum: 0,
        moreNum: 0,
        total: 0, // 总分
      }
      this._loadData()
    },

    // 收到搜索回调
    onCallbackSearch: function (data) {
      const { title, type } = data
      this.formData.type2_id = type
      this.formData.content = title
      this._loadData()
    },

    // 收到 tabs 回调
    onCallbackTab: function ({ item, index }) {
      this.loading(index)
      this[item.func]()
    },

    // 打开或关闭tabs 按钮loading
    loading: function (index) {
      index && (this.tabsIndex = index)
      this.tabsArr[this.tabsIndex].isLoading = !this.tabsArr[this.tabsIndex]
        .isLoading
    },

    // 收到 判断选择 回调
    onCallbackSelect: function (ids) {
      if (this.labelVal === '0') {
        this.clearIds(ids, 'only', 'onlyNum')
      } else if (this.labelVal === '1') {
        this.clearIds(ids, 'more', 'moreNum')
      } else if (this.labelVal === '2') {
        this.clearIds(ids, 'tOrf', 'tOrfNum')
      }
    },
  },
}
</script>

<style lang="stylus" scoped>
.msg {
  display: flex;

  // align-items center;
  .info {
    margin-left: 20px;
    margin-top: 10px;
    font-size: 12px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #999999;
  }
}
</style>
