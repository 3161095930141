<template>
  <el-dialog
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :destroy-on-close="true"
    :show-close="false"
    width="45%"
    :title="isEdit ? '编辑试卷' : '添加试卷'"
    :visible.sync="isShow"
  >
    <div class="all-input">
      <div class="item-input">
        <div class="label"><span class="red">*</span>标题：</div>
        <el-input v-model="formData.name" class="mini"></el-input>
      </div>
      <div class="item-input">
        <div class="label"><span class="red">*</span>分类：</div>
        <el-select class="mini" v-model="formData.type_id" placeholder="请选择">
          <el-option
            v-for="item in options"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </div>
      <div class="item-input">
        <div class="label"><span class="red">*</span>时长(分钟)：</div>
        <el-input-number
          class="mini"
          v-model="formData.time"
          controls-position="right"
          :min="1"
        ></el-input-number>
      </div>
      <div class="item-input">
        <div class="label"><span class="red">*</span>说明：</div>
        <el-input v-model="formData.remarks" class="mini"></el-input>
      </div>
      <div class="item-input">
        <div class="label"><span class="red">*</span>开始时间：</div>
        <el-date-picker
          class="mini"
          v-model="formData.start_time"
          type="datetime"
          placeholder="选择开始时间"
        >
        </el-date-picker>
      </div>
      <div class="item-input">
        <div class="label"><span class="red">*</span>结束时间：</div>
        <el-date-picker
          class="mini"
          v-model="formData.end_time"
          type="datetime"
          placeholder="选择结束时间"
        >
        </el-date-picker>
      </div>
    </div>

    <div slot="footer" class="dialog-footer">
      <el-button type="primary" @click="submit">确 定</el-button>
      <el-button @click="close">取 消</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { getETPSelect, addExamTestPaper, updExamTestPaper } from '@/api/safety'
import { dateMap, dateObj } from '@/utils/util'
export default {
  name: 'addPaper',
  props: {
    loadChange: Function,
  },
  data() {
    return {
      isShow: false, // 是否打开弹窗
      options: [],
      formData: {
        name: '', // 标题
        remarks: '', // 说明
        time: '', // 时长分钟
        start_time: '', // 开始时间
        end_time: '', // 结束时间
        type_id: '', // 分类
      },
      toastData: {
        name: '请输入标题', // 标题
        remarks: '请输入说明', // 说明
        time: '请输入时长', // 时长分钟
        start_time: '请选择开始时间', // 开始时间
        end_time: '请选择结束时间', // 结束时间
        type_id: '请选择分类', // 分类
      },
      isEdit: false,
    }
  },
  created() {
    this._loadSelect()
  },
  methods: {
    _loadSelect: function () {
      getETPSelect().then((res) => {
        this.options = res.result
      })
    },
    open: function (data) {
      this.isShow = true
      this.isEdit = false
      if (data) {
        data.start_time = dateObj(data.start_time)
        data.end_time = dateObj(data.end_time)
        this.formData = data
        this.isEdit = true
      }
    },
    close: function (isLoad) {
      this.isShow = false
      this.clearFormData()
    },
    submit: function () {
      for (const key in this.toastData) {
        if (!this.formData[key]) {
          this.$toast(this.toastData[key])
          return false
        }
      }
      const date = dateMap([this.formData.start_time, this.formData.end_time])
      this.formData.start_time = date[0]
      this.formData.end_time = date[1]

      if (!this.isEdit) this.savePaper()
      else this.editPaper()
    },

    savePaper: function () {
      addExamTestPaper(this.formData).then((res) => {
        this.$toast(res.message)
        if (res.status === 200) {
          this.close(true)
          this.loadChange && this.loadChange()
        }
      })
    },

    editPaper: function () {
      updExamTestPaper(this.formData).then((res) => {
        this.$toast(res.message)
        if (res.status === 200) {
          this.close(true)
          this.loadChange && this.loadChange()
        }
      })
    },

    clearFormData: function() {
      for (const key in this.formData) {
        this.formData[key] = ''
      }
    },
  },
}
</script>

<style lang="stylus" scoped></style>
