<template>
  <div>
    <div v-if='!showType'>
      <SEARCH :searchChange='onCallbackSearch' :list="searchArr"></SEARCH>
      <TABS :tabChange="onCallbackTab" :list="tabsArr"></TABS>
      <TABLE :buttonChange='onCallbackButton' :isShowSele="false" :list="tableArr" :data="dataArr"></TABLE>
      <PAGE :pageChange='onCallbackPage' :limitChange='onCallbackLimit' :count="count"></PAGE>
    </div>
    <div v-else-if='showType === "total"'>
      <NAVBAR :backChange='onCallbackBack' title="考试记录统计"></NAVBAR>
      <total :id='Id'></total>
    </div>

    <div v-else-if='showType === "add"'>
      <NAVBAR :backChange='onCallbackBack' title="添加考题"></NAVBAR>
      <add :arr='arr' :id='Id' :ids='Ids'></add>
    </div>

    <add-paper :loadChange='_loadData' ref='addP'></add-paper>
  </div>
</template>

<script>
import SEARCH from '@/components/search' // 顶部搜索
import TABS from '@/components/tabs' // 横向 button
import TABLE from '@/components/table' // 列表
import PAGE from '@/components/page' // 分页
import NAVBAR from '@/components/navbar' // 导航
import total from './components/total' // 统计
import add from './components/add' // 添加考题
import addPaper from './components/addPaper' // 添加试卷
import { getExamTestPaper, delExamTestPaper, getETPSelect, getExamTestPaperDetail } from '@/api/safety'
export default {
  name: 'SafetyExamTestPaper',
  components: {
    SEARCH,
    TABS,
    TABLE,
    PAGE,
    NAVBAR,
    total,
    add,
    addPaper,
  },
  data() {
    return {
      searchArr: [
        {
          name: '分类',
          type: 'picker',
          options: [],
          key: 'type',
          label: 'name',
          value: 'id',
        },
        {
          name: '标题',
          type: 'input',
          options: [],
          key: 'title',
        },
        {
          name: '创建时间',
          type: 'date',
          key: 'createTime',
        },
      ], // 搜索列表
      tabsArr: [
        {
          type: 'primary',
          name: '添加试卷',
          func: 'openAddPaper',
        },
      ], // 标签列表
      tableArr: [
        {
          prop: 'name',
          label: '标题',
          width: '180',
        },
        {
          prop: 'type_id',
          label: '分类',
          width: '180',
        },
        {
          prop: 'remarks',
          label: '说明',
          width: '180',
        },
        {
          prop: 'score',
          label: '总分',
          width: '100',
        },
        {
          propSta: 'start_time',
          propEnd: 'end_time',
          label: '考试时间',
          width: '380',
          type: 'range',
        },
        {
          prop: 'num',
          label: '题目数量',
          width: '100',
        },
        {
          prop: 'create_at',
          label: '创建时间',
          width: '180',
        },
        {
          prop: '',
          label: '操作',
          width: '',
          type: 'but',
        },
      ], // 表头列表
      dataArr: [], // 数据列表
      butnArr: [
        {
          type: '',
          name: '添加试题',
          func: 'openCom',
          show: 'add',
        },
        {
          type: '',
          name: '统计',
          func: 'openCom',
          show: 'total',
        },
        {
          type: '',
          name: '编辑',
          func: 'editPaper',
        },
        {
          type: 'danger',
          name: '删除',
          func: 'removeExamTestPaper',
        },
      ], // 数据-操作列表
      count: 0, // 总页数
      formData: {
        page: 1,
        limit: 10,
        name: '',
        type_id: '',
        start_time: '',
        end_time: '',
      },
      showType: '',
      Id: '', // 试卷id
      Ids: [], // 考题列表
      arr: [], // 题目
    }
  },
  created() {},
  mounted() {
    if (!this.showType) {
      this._loadData()
      this._loadSelect()
    }
  },
  methods: {

    // 获取下拉框数据
    _loadSelect: function() {
      // 获取分类
      getETPSelect().then(res => {
        const { result } = res
        result.unshift({ id: '', name: '全部' })
        this.searchArr[0].options = result
      })
    },

    // 获取列表数据
    _loadData: function () {
      this.dataArr = []
      getExamTestPaper(this.formData).then((res) => {
        const { result } = res

        this.count = result.count
        result.data.forEach(item => {
          item.buttons = JSON.parse(JSON.stringify(this.butnArr))
        })
        this.dataArr = result.data
      })
    },

    // 删除试卷管理
    removeExamTestPaper: function({ id }) {
      delExamTestPaper(id).then(res => {
        this.$toast(res.message)
        this._loadData()
      })
    },

    // 打开添加试卷
    openAddPaper: function() {
      this.$refs.addP.open()
    },

    // 编辑试卷
    editPaper: function(item) {
      getExamTestPaperDetail(item.id).then(res => {
        const { result } = res
        this.$refs.addP.open(result)
      })
    },

    // 打开统计
    openCom: function(item) {
      this.Id = String(item.id)
      this.Ids = item.qids || []
      this.arr = item.questions || []
      this.$store.commit('SET_BC', false)
      this.showType = item.show
    },

    // 关闭统计、添加
    onCallbackBack: function() {
      this.$store.commit('SET_BC', true)
      this.showType = ''
      this._loadData()
    },

    // 收到搜索回调
    onCallbackSearch: function(data) {
      const { type, title, createTime } = data
      this.formData.start_time = createTime[0]
      this.formData.end_time = createTime[1]
      this.formData.name = title
      this.formData.type_id = type
      this._loadData()
    },
    // 收到 tabs 回调
    onCallbackTab: function ({ item, index }) {
      this[item.func]()
    },

    // 收到 table内方法 回调
    onCallbackButton: function(item, func) {
      this[func](item)
    },

    // 收到改变页码回调
    onCallbackPage: function(page) {
      this.formData.page = page
      this._loadData()
    },
    // 收到改变一页数量回调
    onCallbackLimit: function(limit) {
      this.formData.limit = limit
      this._loadData()
    },
  },
}
</script>

<style lang="stylus" scoped></style>
