<template>
  <div class="navbar">
    <el-button @click="gotoBack">返回</el-button>
    <div class="title reg"
    >{{ title }}</div>
  </div>
</template>

<script>
export default {
  name: 'navbar',
  props: {
    title: {
      type: String,
      default() {
        return ''
      },
    },
    backChange: Function,
  },
  methods: {
    gotoBack: function() {
      console.log('触发； ？')
      this.backChange && this.backChange()
    },
  },
}
</script>

<style lang="stylus" scoped>
.navbar {
  height: 64px;
  background: #FFFFFF;
  border-radius: 4px;
  padding-left 20px;
  display flex;
  align-items center;
  .title {
    font-size: 18px;
    color: #515A6E;
    margin-left: 15px;
  }
}
</style>
