<template>
  <div id="detail-z">
    <TABLE :switchChange='onCallbackSwitch' :buttonChange='onCallbackButton' :isShowSele="false" :list="tableArr" :data="dataArr"></TABLE>
    <PAGE :pageChange='onCallbackPage' :limitChange='onCallbackLimit' :count="count"></PAGE>
  </div>
</template>

<script>
import TABLE from '@/components/table' // 列表
import PAGE from '@/components/page' // 分页

import { getExamTestPaperTotal } from '@/api/safety'
export default {
  name: 'TestPaperTotal',
  components: {
    TABLE,
    PAGE,

  },
  data() {
    return {
      tableArr: [
        {
          prop: 'user_id',
          label: '姓名',
          width: '233',
        },
        {
          prop: 'score',
          label: '最新得分',
          width: '350',
        },
        {
          prop: 'time',
          label: '答题时长',
          width: '350',
        },
        {
          prop: 'name',
          label: '标题',
          width: '350',
        },
        {
          prop: 'all_score',
          label: '总分',
          width: '350',
        },
      ], // 表头列表
      dataArr: [], // 数据列表
      count: 0, // 总页数
      formData: {
        page: 1,
        limit: 10,
        id: '',
      },
    }
  },
  props: {
    id: {
      type: String,
      default() {
        return ''
      },
    },
  },
  created() {},
  mounted() {
    this._loadData()
  },
  methods: {
    _loadData: function () {
      this.formData.id = this.id
      getExamTestPaperTotal(this.formData).then((res) => {
        const { result } = res

        this.count = result.count
        result.data.forEach(item => {
        })
        this.dataArr = result.data
      })
    },

    // 收到搜索回调
    onCallbackSearch: function(data) {
      const { fenceName, fenceProject } = data
      this.formData.name = fenceName
      this.formData.p_id = fenceProject
      this._loadData()
    },

    // 收到 table内方法 回调
    onCallbackButton: function(item, func) {
      this[func](item)
    },
    // 收到 表格内 switch 方法回调
    onCallbackSwitch: function(item, func) {
      this[func](item)
    },

    // 收到改变页码回调
    onCallbackPage: function(page) {
      this.formData.page = page
      this._loadData()
    },
    // 收到改变一页数量回调
    onCallbackLimit: function(limit) {
      this.formData.limit = limit
      this._loadData()
    },
  },
}
</script>

<style lang="stylus" scoped>

</style>
